/* components/Common.css */
.menu-title {
    flex-grow: 1;
  }
  
  .menu-links {
    display: flex;
    align-items: center;
  }
  
  .menu-links a {
    color: #000000;
    text-decoration: none;
    margin-right: 20px;
  }
  
  .menu-links .logoutButton {
    margin-left: auto;
    cursor: pointer;
  }
  
  .menubarLink a,
  .menubarLink button {
    text-transform: none;
    font-weight: normal;
  }
  
  @media (max-width: 600px) {
    .menu-links {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .menu-links a {
      margin-bottom: 10px;
    }
  }
  
  
  
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    width: 30%;
    padding: 20px;
    background-color: #56DBA6;
    margin: 10px;
    border-radius: 10px;
  }
  
  .feature p {
    font-size: 1.2em;
    margin: 0;
  }
  
  /* Side bar */
  
  
  /* Side bar  */
  
  /* Add CSS styles for the LocationAutocomplete component */
  /* Common.css */
  .location-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestion-item {
    padding: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #e0e0e0;
  }
  
  @media screen and (max-width: 768px) {
    .feature {
      width: 100%;
    }
  }
  
  
  